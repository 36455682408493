import { useContext } from 'react'
import summaryContext from 'context/summary'
import { ViewMode } from 'gql/survey_analysis_summary'
import { getRGBA } from 'utils/analysis'
import oc from 'open-color'
import { getColorConcentration } from 'utils'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { withStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
    index: number;
    length: number;
    onChange: (a: number) => void;
}

interface PageBoxProps extends PaperProps {
    viewmode: ViewMode
    print: number
}

const useStyles = makeStyles(theme => ({
    root: (props: { print: boolean; viewmode: ViewMode; }) => {
        const { viewmode } = props

        return {
            display: 'flex',
            justifyContent:  viewmode === ViewMode.Edit ? 'flex-start' : 'flex-end',
            position: 'sticky',
            bottom: 0,
            [theme.breakpoints.down('xs')]: {
                position: 'fixed',
                width: '100%',
                left: 0,
                bottom: viewmode === ViewMode.View ? 46 : -11
            }
        }
    }
}))

const PageBox = withStyles(theme => ({
    root: (props: PageBoxProps) => {
        const { print } = props

        return {
            display: 'flex',
            flexWrap: 'wrap',
            fontWeight: 700,
            marginBottom: 10,
            [theme.breakpoints.down('xs')]: {
                display: print ? 'none' : 'flex',
                borderRadius: 0,
                width: '100%',
                borderBottom: `1px solid ${oc.gray[1]}`,
            },
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 35,
                height: 35,
                cursor: 'pointer'
            }
        }
    }
}))((props: PageBoxProps) => <Paper {...props}/>)

function PageBoxComponent(props: Props) {
    const { index, length, onChange } = props

    const { viewMode, print, design } = useContext(summaryContext)

    const classes = useStyles({ viewmode: viewMode, print })

    // 보기 폰트모양
    const fontFamily = design.font_family

    // 바 배경색
    const bg = viewMode === ViewMode.View ? 'white' : getRGBA({color: design.bar, opacity: .7})

    // 바 색
    const color = viewMode === ViewMode.View ? 'black' : getColorConcentration(design.bar) ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)'

    const arr = new Array(Math.ceil(length / 20)).fill(true)

    return (
        <div className={classes.root}>
            <PageBox viewmode={viewMode} print={print ? 1 : 0} style={{
                fontFamily,
                background: bg,
                color
            }}>
                {
                    arr.map((c, i) => {
                        const borderLeft = i === 0 ? 'none' : `2px dotted rgba(20, 20, 20, .1)`

                        return (
                            <div 
                                key={i} 
                                style={i === index ? { fontWeight: 900, fontSize: 20, borderLeft } : { fontSize: 16, fontWeight: 500, borderLeft }} 
                                onClick={() => onChange(i)}
                            >
                                {(i + 1)}
                            </div>
                        )
                    })
                }
            </PageBox>
        </div>
    )
}


export default PageBoxComponent